.slider-container {
   position: relative;
   width: 100%;
   height: 100vh;
   overflow: hidden;
 }
 
 .slider-slide {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
 }
 
 .slider-content {
   position: relative;
   z-index: 1;
 }
 
 .slider-image {
   object-fit: cover;
   width: 100%;
   height: 100%;
 }
 
 .slider-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.4), transparent);
 }
 
 .slider-button {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   background-color: rgba(0, 0, 0, 0.5);
   color: white;
   border: none;
   padding: 10px;
   cursor: pointer;
   z-index: 2;
 }
 
 .slider-button:hover {
   background-color: rgba(0, 0, 0, 0.7);
 }
 
 .slider-button-prev {
   left: 10px;
 }
 
 .slider-button-next {
   right: 10px;
 }
 
 .slider-pagination {
   position: absolute;
   bottom: 20px;
   left: 50%;
   transform: translateX(-50%);
   display: flex;
   gap: 10px;
   z-index: 2;
 }
 
 .slider-pagination-dot {
   width: 10px;
   height: 10px;
   border-radius: 50%;
   background-color: rgba(255, 255, 255, 0.5);
   cursor: pointer;
 }
 
 .slider-pagination-dot.active {
   background-color: white;
 }
 
 